import { PARENT_MODAL_DOMAIN, modalMessages } from "../constants/responses";

const handleCheckoutProRedirect = () => {
  const params = new URLSearchParams(window.location.search);
  if (params.get("origin") === "link") {
    window.close();
    setTimeout(() => {
      if (!window.closed) {
        window.location.href = "/done";
      }
    }, 1);
  } else {
    PARENT_MODAL_DOMAIN.forEach((domain) => {
      window.parent.postMessage(
        { message: modalMessages.CHECKOUTPRO_REDIRECT },
        domain
      );
    });
  }
};

export default handleCheckoutProRedirect;
