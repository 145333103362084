import { PARENT_MODAL_DOMAIN, modalMessages } from "../constants/responses";

const handleError = () => {
  PARENT_MODAL_DOMAIN.forEach((domain) => {
    window.parent.postMessage(
      { message: modalMessages.ERROR },
      domain
    );
  });
};

export default handleError;
