const theme = {
  colors: {
    primary: {
      main: "#0072CE",
      dark: "#101820",
      light: "#001E62",
    },
    secondary: {
      main: "#32BBD0",
      dark: "#595DB6",
    },
    tertiary: {
      main: "#B9113F",
      light: "#28A745",
    },
    neutral: {
      main: "#000000",
      dark: "#333333",
      light: "#FFFFFF",
    },
    success: {
      main: "#28A745",
    },
    error: {
      main: "#DC3545",
    },
  },
};

export default theme;
