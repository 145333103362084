import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";

import CardProcess from "./CardProcess";
import CheckoutPro from "./CheckoutPro";
import Tabs from "../../components/Tabs";
import Container from "../../components/Container";
import LoadingModal from "../../components/LoadingModal";
import Modal from "../../components/Modal";
import Header from "../../components/Header";
import Body from "../../components/Body";
import Description from "../../components/Description";
import Bold from "../../components/Bold";
import List from "../../components/List";
import ListItem from "../../components/ListItem";
import Footer from "../../components/Footer";
import Button, { ButtonOutline } from "../../components/Button";
import useWidth from "../../hooks/useWidth";
import useCards from "../../hooks/useCards";
import useCustomer from "../../hooks/useCustomer";
import useAddCard from "../../hooks/useAddCard";
import handleSuccess, {
  handlePendingReviewManual,
} from "../../helpers/handleSuccess";
import handleError from "../../helpers/handleError";
import handleBackToBind from "../../helpers/handleBackToBind";
import { frequencyType } from "../../constants/frequencyType";
import { paymentType } from "../../constants/paymentType";
import {
  errors,
  loadingMessages,
  paymentStatus,
  success,
} from "../../constants/responses";
import { createPayment, createSubscription } from "../../api/payments";

const CreatePayment = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const type = searchParams.get("type");
  const origin = searchParams.get("origin");
  const formMethods = useForm();
  const { isMobile } = useWidth();
  const { cards, handleFetchCards, isLoadingCards, errorCards } = useCards();
  const { tokenization } = useAddCard({
    onTokenizationSuccess: () => {
      setIsAddingCard(false);
      formMethods.reset();
      handleFetchCards();
    },
  });
  const { customer, isLoadingCustomer, errorCustomer } = useCustomer();
  const [isMercadopagoCheckoutOpen, setIsMercadopagoCheckoutOpen] =
    useState(false);
  const [isAddingCard, setIsAddingCard] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalState, setModalState] = useState({
    isOpen: false,
    title: "",
    message: "",
  });
  const [cardSelected, setCardSelected] = useState(0);

  const handlePay = () => {
    setLoading(true);
    createPayment({
      cardId: cards[cardSelected].cardId,
      token,
    })
      .then((response) => {
        if (response?.data?.success) {
          handleSuccess();
          setModalState({
            isOpen: true,
            type: "success",
            title: success.POST_PAYMENT.title,
            message: success.POST_PAYMENT.message,
          });
        } else {
          handleError();
          setModalState({
            isOpen: true,
            type: "error",
            title: errors.POST_PAYMENT.title,
            message: response?.data?.message
              ? `${errors.POST_PAYMENT.message}\n\nMensaje de api mercadopago: ${response.data.message}`
              : errors.POST_PAYMENT.message,
          });
        }
      })
      .catch((error) => {
        if (
          error?.response?.data?.message === paymentStatus.PENDING_REVIEW_MANUAL
        ) {
          handlePendingReviewManual();
          setModalState({
            isOpen: true,
            type: "success",
            title: success.PENDING_REVIEW_MANUAL.title,
            message: success.PENDING_REVIEW_MANUAL.message(
              customer?.subscription?.description
            ),
            ctaLabel: "Regresar a Bind",
          });
        } else if (
          error?.response?.data?.error === errors.TOKEN_HAS_EXPIRED.value
        ) {
          handleError();
          setModalState({
            isOpen: true,
            type: "error",
            title: errors.TOKEN_HAS_EXPIRED.title,
            message: errors.TOKEN_HAS_EXPIRED.message,
          });
        } else {
          handleError();
          setModalState({
            isOpen: true,
            type: "error",
            title: errors.POST_PAYMENT.title,
            message: error?.response?.data?.message
              ? `${errors.POST_PAYMENT.message}\n\nMensaje de api mercadopago: ${error.response.data.message}`
              : errors.POST_PAYMENT.message,
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubscribe = () => {
    setLoading(true);
    createSubscription({
      cardId: cards[cardSelected].cardId,
      token,
    })
      .then((response) => {
        if (response?.data?.success === paymentStatus.PENDING_REVIEW_MANUAL) {
          handlePendingReviewManual();
          setModalState({
            isOpen: true,
            type: "success",
            title: success.PENDING_REVIEW_MANUAL.title,
            message: success.PENDING_REVIEW_MANUAL.message(
              customer?.subscription?.description
            ),
            ctaLabel: "Regresar a Bind",
          });
        } else if (response?.data?.success) {
          handleSuccess();
          if (type === paymentType.RECURRING_PRORRATED) {
            setModalState({
              isOpen: true,
              type: "success",
              title: success.POST_PAYMENT_PRORRATEO.title,
              message: success.POST_PAYMENT_PRORRATEO.message,
            });
          } else {
            setModalState({
              isOpen: true,
              type: "success",
              title: success.POST_PAYMENT.title,
              message: success.POST_PAYMENT.message,
            });
          }
        } else {
          handleError();
          setModalState({
            isOpen: true,
            type: "error",
            title: errors.POST_PAYMENT.title,
            message: response?.data?.message
              ? `${errors.POST_PAYMENT.message}\n\nMensaje de api mercadopago: ${response.data.message}`
              : errors.POST_PAYMENT.message,
          });
        }
      })
      .catch((error) => {
        handleError();
        if (error?.response?.data?.error === errors.TOKEN_HAS_EXPIRED.value) {
          setModalState({
            isOpen: true,
            type: "error",
            title: errors.TOKEN_HAS_EXPIRED.title,
            message: errors.TOKEN_HAS_EXPIRED.message,
          });
        } else {
          setModalState({
            isOpen: true,
            type: "error",
            title: errors.POST_PAYMENT.title,
            message: error?.response?.data?.message
              ? `${errors.POST_PAYMENT.message}\n\nMensaje de api mercadopago: ${error.response.data.message}`
              : errors.POST_PAYMENT.message,
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const renderDescription = () => {
    if (type === paymentType.SINGLE) {
      return (
        <Description>
          Hola <Bold>{customer?.customer?.first_name}</Bold>
          <br />
          <br />
          <List>
            <ListItem>
              Realiza el pago de tu plan {" "}
              <Bold>{customer?.subscription?.description}</Bold>.
            </ListItem>
            <ListItem>
              Monto total a pagar:{" "}
              <Bold>
                {parseFloat(
                  customer?.subscription?.transactionAmount
                ).toLocaleString("es-MX", {
                  style: "currency",
                  currency: "MXN",
                })}
              </Bold>
              .
            </ListItem>
          </List>
        </Description>
      );
    } else if (type === paymentType.RECURRING) {
      return (
        <Description>
          Hola <Bold>{customer?.customer?.first_name}</Bold>
          <br />
          <br />
          <List>
            <ListItem>
              Realiza el pago de tu plan{" "}
              <Bold>{customer?.subscription?.description}</Bold>.
            </ListItem>
            <ListItem>
              Monto total a pagar:{" "}
              <Bold>
                {parseFloat(
                  customer?.subscription?.transactionAmount
                ).toLocaleString("es-MX", {
                  style: "currency",
                  currency: "MXN",
                })}
              </Bold>
              .
            </ListItem>
            <ListItem>
              Este pago cubre {" "}
              <Bold>
                {customer?.subscription?.frequency}{" "}
                {frequencyType[customer?.subscription?.frequencyType]}
              </Bold>
              {" "} de servicio.
            </ListItem>
          </List>
        </Description>
      );
    } else if (type === paymentType.RECURRING_PRORRATED) {
      return (
        <Description>
          Hola <Bold>{customer?.customer?.first_name}</Bold>
          <br />
          <br />
          <List>
            <ListItem>
              Se realizará un cargo único de{" "}
              <Bold>
                {parseFloat(
                  customer?.subscription?.singlePaymentAmount
                ).toLocaleString("es-MX", {
                  style: "currency",
                  currency: "MXN",
                })}
              </Bold>
              .
            </ListItem>
            <ListItem>
              En la siguiente fecha de pago, se cobrará el plan{" "}
              <Bold>{customer?.subscription?.description}</Bold>.
            </ListItem>
            <ListItem>
              El costo mensual de tu plan será de{" "}
              <Bold>
                {parseFloat(
                  customer?.subscription?.transactionAmount
                ).toLocaleString("es-MX", {
                  style: "currency",
                  currency: "MXN",
                })}
              </Bold>
              .
            </ListItem>
            <ListItem>
              Este monto cubre{" "} 
              <Bold>
                {customer?.subscription?.frequency}{" "}
                {frequencyType[customer?.subscription?.frequencyType]}
              </Bold>
              {" "} de servicio.
            </ListItem>
          </List>
        </Description>
      );
    }
  };

  const handleCancel = () => {
    handleBackToBind();
  };

  const handleBack = () => {
    setIsAddingCard(false);
  };

  const handleSave = () => {
    if (isAddingCard || cards.length === 0) {
      formMethods.handleSubmit(tokenization)();
    } else if (
      type === paymentType.RECURRING ||
      type === paymentType.RECURRING_PRORRATED
    ) {
      handleSubscribe();
    } else {
      handlePay();
    }
  };

  const renderSaveLabel = () => {
    if (isAddingCard || cards.length === 0) {
      return "Agregar";
    } else if (
      type === paymentType.RECURRING ||
      type === paymentType.RECURRING_PRORRATED
    ) {
      return "Suscribirse";
    } else {
      return "Pagar";
    }
  };

  useEffect(() => {
    if (errorCustomer.message || errorCards.message) {
      handleError();
      setModalState({
        isOpen: true,
        type: "error",
        title: errorCustomer.title || errorCards.title,
        message: errorCustomer.message || errorCards.message,
        onAccept: handleBackToBind,
        onCancel: handleBackToBind,
      });
    } else {
      setModalState({
        isOpen: false,
        title: "",
        message: "",
      });
    }
  }, [errorCustomer, errorCards]);

  return (
    <Container>
      <LoadingModal
        isOpen={isLoadingCards || isLoadingCustomer}
        title={loadingMessages.COMMON.title}
        message={loadingMessages.COMMON.message}
      />
      <LoadingModal
        isOpen={loading}
        title={loadingMessages.POST_PAYMENT.title}
        message={loadingMessages.POST_PAYMENT.message}
      />
      <Modal
        isOpen={modalState.isOpen}
        type={modalState.type}
        title={modalState.title}
        message={modalState.message}
        ctaLabel={modalState.ctaLabel}
        onAccept={handleBackToBind}
        onCancel={handleBackToBind}
      />
      <Header>Realizar pago</Header>
      <Body>
        {renderDescription()}
        <Tabs
          tabData={[
            {
              label: isMobile ? (
                <>
                  Tarjeta de
                  <br />
                  débito o crédito
                </>
              ) : (
                <>
                  Tarjeta de débito o crédito
                  <br />
                  <span style={{ fontSize: 12 }}>
                    (Pago en una sola exhibición)
                  </span>
                </>
              ),
              content: () => (
                <CardProcess
                  formMethods={formMethods}
                  isAddingCard={isAddingCard}
                  setIsAddingCard={setIsAddingCard}
                  cards={cards || []}
                  cardSelected={cardSelected}
                  setCardSelected={setCardSelected}
                />
              ),
            },
            {
              label: isMobile ? (
                <>
                  Otros métodos
                  <br />
                  de pago
                </>
              ) : (
                "Otros métodos de pago"
              ),
              content: () => <CheckoutPro customer={customer} />,
            },
          ]}
          onSelect={(tab) => {
            if (tab === 0) {
              setIsMercadopagoCheckoutOpen(false);
            } else {
              setIsMercadopagoCheckoutOpen(true);
            }
          }}
        />
      </Body>
      <Footer>
        {isAddingCard && (
          <ButtonOutline onClick={handleBack}>Atrás</ButtonOutline>
        )}
        {!isAddingCard && origin !== "link" && (
          <ButtonOutline onClick={handleCancel}>Cancelar</ButtonOutline>
        )}
        {!isMercadopagoCheckoutOpen && (
          <Button onClick={handleSave}>{renderSaveLabel()}</Button>
        )}
      </Footer>
      {type === paymentType.RECURRING && (
        <Footer style={{ padding: 0, marginTop: -15, marginRight: 30 }}>
          <Description style={{ fontWeight: "500", fontSize: 14 }}>
            Tu tarjeta será cargada cada {customer?.subscription?.frequency}{" "}
            {frequencyType[customer?.subscription?.frequencyType]}
          </Description>
        </Footer>
      )}
    </Container>
  );
};

export default CreatePayment;
