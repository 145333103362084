import { PARENT_MODAL_DOMAIN, modalMessages } from "../constants/responses";

const handleSuccess = () => {
  PARENT_MODAL_DOMAIN.forEach((domain) => {
    window.parent.postMessage(
      { message: modalMessages.SUCCESS },
      domain
    );
  });
};

export const handlePendingReviewManual = () => {
  PARENT_MODAL_DOMAIN.forEach((domain) => {
    window.parent.postMessage(
      { message: modalMessages.PENDING_REVIEW_MANUAL },
      domain
    );
  });
};

export default handleSuccess;
