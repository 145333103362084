import Container from "../../components/Container";
import Header from "../../components/Header";
import Body from "../../components/Body";
import Description from "../../components/Description";

const Done = () => (
  <Container>
    <Header>Es seguro cerrar esta ventana</Header>
    <Body>
      <Description>
        Si la ventana no se cerró automáticamente, puedes hacerlo manualmente.
      </Description>
    </Body>
  </Container>
);

export default Done;
