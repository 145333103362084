import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import Done from "./views/Done";
import AddCard from "./views/AddCard";
import PaymentMethods from "./views/PaymentMethods";
import PaymentProcess from "./views/PaymentProcess";
import PaymentProcessResult from "./views/PaymentProcessResult";
import { routes } from "./constants/routes";
import theme from "./styles/theme";
import "./App.css";

const App = () => {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path={routes.DONE} element={<Done />} />
          <Route path={routes.ADD_CARD} element={<AddCard />} />
          <Route path={routes.PAYMENT_METHODS} element={<PaymentMethods />} />
          <Route path={routes.PAYMENT_PROCESS} element={<PaymentProcess />} />
          <Route
            path={routes.PAYMENT_PROCESS_SUCCESS}
            element={<PaymentProcessResult />}
          />
          <Route
            path={routes.PAYMENT_PROCESS_FAILURE}
            element={<PaymentProcessResult />}
          />
          <Route
            path={routes.PAYMENT_PROCESS_PENDING}
            element={<PaymentProcessResult />}
          />
        </Routes>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default App;
